<template>
  <v-app style="background-color: #f0f1f4">
    <v-container class="mx-auto">
      <v-sheet width="500">
        <v-card class="theme--light">
          <div class="px-3 px-md-10 py-8">
            <h3 class="mb-2 text-center">Welcome To Ecommerce</h3>
            <h5
              class="
                font-600
                grey--text
                text--darken-3 text-sm
                mb-9
                text-center
              "
            >
              Log in with email &amp; password
            </h5>
            <p class="text-14 mb-1">Email or Phone Number</p>
            <div
              class="
                v-input
                mb-4
                v-input--hide-details v-input--dense
                theme--light
                v-text-field
                v-text-field--is-booted
                v-text-field--enclosed
                v-text-field--outlined
                v-text-field--placeholder
              "
            >
              <div class="v-input__control">
                <div class="v-input__slot">
                  <fieldset aria-hidden="true">
                    <legend style="width: 0px">
                      <span class="notranslate">​</span>
                    </legend>
                  </fieldset>
                  <div class="v-text-field__slot">
                    <input
                      id="input-962"
                      placeholder="example@mail"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
            <p class="text-14 mb-1">Password</p>
            <div
              class="
                v-input
                mb-4
                v-input--hide-details v-input--dense
                theme--light
                v-text-field
                v-text-field--is-booted
                v-text-field--enclosed
                v-text-field--outlined
                v-text-field--placeholder
              "
            >
              <div class="v-input__control">
                <div class="v-input__slot">
                  <fieldset aria-hidden="true">
                    <legend style="width: 0px">
                      <span class="notranslate">​</span>
                    </legend>
                  </fieldset>
                  <div class="v-text-field__slot">
                    <input
                      id="input-963"
                      placeholder="example@mail"
                      type="password"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="
                text-capitalize
                font-600
                v-btn v-btn--block v-btn--is-elevated v-btn--has-bg
                theme--light
                v-size--default
              "
              style="background: #d23f57"
            >
              <span class="v-btn__content" style="color: white !important"
                >Login</span
              >
            </button>
            <div class="mx-auto col-lg-8 col-10">
              <div class="d-flex align-center my-1">
                <hr
                  role="separator"
                  aria-orientation="horizontal"
                  class="v-divider theme--light"
                />
                <span class="mx-4">on</span>
                <hr
                  role="separator"
                  aria-orientation="horizontal"
                  class="v-divider theme--light"
                />
              </div>
            </div>
            <button
              type="button"
              class="
                text-capitalize
                font-600
                mb-4
                v-btn v-btn--block v-btn--is-elevated v-btn--has-bg
                theme--dark
                v-size--default
                indigo
              "
            >
              <span class="v-btn__content"
                ><i
                  aria-hidden="true"
                  class="
                    v-icon
                    notranslate
                    me-3
                    v-icon--left
                    mdi mdi-facebook
                    theme--dark
                  "
                ></i>
                Continue with facebook
              </span>
            </button>
            <button
              type="button"
              class="
                text-capitalize
                font-600
                mb-4
                v-btn v-btn--block v-btn--is-elevated v-btn--has-bg
                theme--dark
                v-size--default
                blue
                darken-2
              "
            >
              <span class="v-btn__content"
                ><i
                  aria-hidden="true"
                  class="
                    v-icon
                    notranslate
                    me-3
                    v-icon--left
                    mdi mdi-google
                    theme--dark
                  "
                ></i>
                Continue with Google
              </span>
            </button>
            <div class="text-14 text-center my-3" style="font-size: 12pt">
              Don't have account?
              <a href="/SignUp" class="grey--text text--darken-4 font-600"
                >Sign Up</a
              >
            </div>
          </div>
          <div class="py-4" style="background-color: #f6f9fc; font-size: 12pt">
            <div class="text-center">
              <span class="grey--text text--darken-1"
                >Forgot Your Password
                <a
                  href="/"
                  class="
                    ms-2
                    grey--text
                    text--darken-4
                    font-600
                    nuxt-link-active
                  "
                  >Reset It</a
                ></span
              >
            </div>
          </div>
        </v-card>
      </v-sheet>
    </v-container>
  </v-app>
</template>

<script>
const fb = require("../firebaseConfig.js");
export default {
  name: "Login",
  data() {
    return {
      loadingGoogle: false,
      loading: false,
    };
  },
  methods: {
    googleSignIn() {
      this.loadingGoogle = true;
      fb.auth
        .signInWithPopup(fb.googleProvider)
        .then((result) => {
          var user = result.user;

          this.$store.commit("setCurrentUser", user);

          fb.usersCollection
            .doc(user.uid)
            .get()
            .then((userData) => {
              if (userData.exists) {
                this.$store.dispatch("fetchUserProfile");
                this.performingRequest = false;
                this.$router.push("/dashboard");
              } else {
                console.log("AAAA");
                fb.usersCollection
                  .doc(user.uid)
                  .set({
                    name: user.displayName,
                    email: user.email,
                    provider: "google",
                    createdAt: new Date(),
                  })
                  .then(() => {
                    this.$store.dispatch("fetchUserProfile");
                    this.performingRequest = false;
                    this.$router.push("/");
                  })
                  .catch((err) => {
                    console.log(err);
                    this.performingRequest = false;
                    this.errorMsg = err.message;
                  });
              }
              this.loadingGoogle = false;
            });
        })
        .catch(function (error) {
          this.loadingGoogle = false;
          console.log(error);
          // Handle Errors here.
          // The email of the user's account used.
          // The firebase.auth.AuthCredential type that was used.
          this.$store.dispatch("setEnabledFalse");
          // ...
        });
    },
  },
};
</script>
